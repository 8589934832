export const data = [
    {
        "position": 1,
        "videoId": "okwizbfyyb",
        "videoTitle": "Intro to XYWAV",
        "videoDescription": "Learn about narcolepsy and XYWAV, the first and only lower-sodium oxybate treatment option FDA-approved for cataplexy and/or excessive daytime sleepiness (EDS) in patients with narcolepsy. Please see Important Safety Information and full Prescribing Information, including BOXED Warning, and Medication Guide above.",
        "speakerName": "Name",
        "speakerTitle": "Title",
        "thumbnail": "https://embed-ssl.wistia.com/deliveries/ef0a62561526cff42ae2bd3504f6c9e9.png",
        "videoTranscript": [
            { "paragraph": "Hello and welcome to the Xywav Educational webinar sponsored by Jazz Pharmaceuticals. My name is Madeline Phipps and I will be facilitating tonight’s program." },
            { "paragraph": "As housekeeping notes, we have made the full Prescribing Information and medication guide available as a PDF download under the handouts tab, to the right of your screen." },
            { "paragraph": "If you have questions for the presenter during the webinar, we encourage you to submit those by using the Q&A box, which can be found by clicking the “chat” icon on the bottom right side of your screen. Type in your question, click send, and we will address your questions throughout the presentation. Please note that we may not be able to answer all questions due to guidelines or time. If you submit a question to the presenter and you find that we have not addressed it, it is because we are not able to answer it in this setting. Please be assured that we do receive all questions and we will try to answer them as the presentation continues. If we are not able to answer your question, we encourage you to discuss it with your healthcare team. We appreciate your patience and understanding." },
            { "paragraph": "If you are disconnected from the webinar, you can re-enter the program by following the instructions that have been provided to you in the confirmation email.  If you are still having difficulty connecting to the webinar, you may call the technical support hotline at 1-844-390-8697." },
            { "paragraph": "We expect the presentation to be about 45 minutes." },
            { "paragraph": "As mentioned, the full prescribing information, and medication guide are available for download. You can read the Important Safety Information, which will be reviewed as part of Anne Marie Morse ’s presentation." },
            { "paragraph": "At this time, I would like to introduce our healthcare provider speaker for tonight’s webinar." },
            { "paragraph": "Welcome Anne Marie Morse." },
            { "paragraph": "Before we begin I would like to make you aware of some important information in relation to Jazz Pharmaceuticals’ activities in monitoring and ensuring patient safety, and how these activities could impact you if you were to mention a side effect, or other piece of safety‐related information while raising a question in the webinar chat." },
            { "paragraph": "Jazz Pharmaceuticals is committed to ensuring patient safety. With this intention, one of the activities that Jazz undertakes is the collection and analysis of reports of adverse events and side effects that we are made aware of through the use of our medicines." },
            { "paragraph": "If you mention an adverse event while raising a question during this webinar," },
            { "paragraph": "the Jazz Drug Safety team may wish to reach out to you or your physician to understand your experience and the" },
            { "paragraph": "adverse event that you reported. If you would prefer not to receive any communication from the Jazz Drug Safety" },
            { "paragraph": "team, you are able to withhold consent for your contact details to be passed to Jazz Drug Safety." },
            { "paragraph": "XYWAV™ (calcium, magnesium, potassium, and sodium oxybates) oral solution, 0.5 g/mL total salts (equivalent to 0.413 g/mL of" },
            { "paragraph": "oxybate) is a prescription medicine used to treat the following symptoms in people 7 years of age or older with narcolepsy:" },
            { "paragraph": "• sudden onset of weak or paralyzed muscles (cataplexy)" },
            { "paragraph": "• excessive daytime sleepiness (EDS)" },
            { "paragraph": "Important Safety Information" },
            { "paragraph": "WARNING: Taking XYWAV with other central nervous system (CNS) depressants such as medicines used to make you or your" },
            { "paragraph": "child fall asleep, including opioid analgesics, benzodiazepines, sedating antidepressants, antipsychotics, sedating antiepileptic" },
            { "paragraph": "medicines, general anesthetics, muscle relaxants, alcohol, or street drugs, may cause serious medical problems," },
            { "paragraph": "including trouble breathing (respiratory depression), low blood pressure (hypotension), changes in alertness (drowsiness), fainting (syncope), and death." },
            { "paragraph": "The active ingredient of XYWAV is a form of gamma hydroxybutyrate (GHB). Abuse or misuse of illegal GHB alone or with other" },
            { "paragraph": "drugs that cause changes in alertness (or consciousness) has caused serious side effects. These effects include seizures, trouble" },
            { "paragraph": "breathing (respiratory depression), changes in alertness (drowsiness), coma, and death. Call your doctor right away if you or your child has any of these serious side effects." },
            { "paragraph": "Because of these risks, you have to go through the XYWAV and XYREM REMS Program to have your or your child’s prescription for XYWAV filled." },
            { "paragraph": "Do not take XYWAV if you take or your child takes other sleep medicines or sedatives (medicines that cause sleepiness), drinks alcohol, or has a rare problem called succinic semialdehyde dehydrogenase deficiency." },
            { "paragraph": "Keep XYWAV in a safe place to prevent abuse and misuse. Selling or giving away XYWAV may harm others, and is against the law. Tell your doctor if you have ever abused or been dependent on alcohol, prescription medicines, or street drugs." },
            { "paragraph": "Anyone who takes XYWAV should not do anything that requires them to be fully awake or is dangerous, including driving a car, using heavy machinery, or flying an airplane, for at least 6 hours after taking XYWAV. Those activities should not be done until you know how XYWAV affects you or your child." },
            { "paragraph": "XYWAV can cause serious side effects, including the following:" },
            { "paragraph": "• Breathing problems, including slower breathing, trouble breathing, and/or short periods of not breathing while sleeping (sleep apnea). People who already have breathing or lung problems have a higher chance of having breathing problems when they use XYWAV." },
            { "paragraph": "• Mental health problems, including confusion, seeing or hearing things that are not real (hallucinations), unusual or disturbing thoughts (abnormal thinking), feeling anxious or upset, depression, thoughts of killing yourself or trying to kill yourself, increased tiredness, feelings of guilt or worthlessness, or difficulty concentrating. Tell your doctor if you or your child have or had depression or have tried to harm yourself or themselves. Call your doctor right away if you have or your child has symptoms of mental health problems or a change in weight or appetite." },
            { "paragraph": "Sleepwalking. Sleepwalking can cause injuries. Call your doctor if you or your child starts sleepwalking. Your doctor should check you or your child." },
            { "paragraph": "The most common side effects of XYWAV in adults include headache, nausea, dizziness, decreased appetite, parasomnia (a sleep disorder that can include abnormal dreams, abnormal rapid eye movement (REM) sleep, sleep paralysis, sleep talking, sleep terror, sleep-related eating disorder, sleepwalking, and other abnormal sleep-related events), diarrhea, excessive sweating (hyperhidrosis), anxiety and vomiting." },
            { "paragraph": "The most common side effects of XYWAV in children include nausea, bedwetting, vomiting, headache, weight decrease, decreased appetite, dizziness, and sleepwalking." },
            { "paragraph": "XYWAV can cause physical dependence and craving for the medicine when it is not taken as directed. These are not all the possible side effects of XYWAV." },
            { "paragraph": "You are encouraged to report negative side effects of prescription drugs to the FDA. Visit www.fda.gov/medwatch, or call 1-800-FDA-1088." },
            { "paragraph": "Is there a cure for narcolepsy?  -    Yes    -	No " },
            { "paragraph": "Do you think that people with narcolepsy may be more likely to have certain other medical conditions in addition to narcolepsy, compared with controls? -    True    - 	False" },
            { "paragraph": "What is the average American’s daily dietary sodium intake?  -About 1600 mg   -About 2300 mg   -About 3400 mg  -About 4100 mg" },
            { "paragraph": "Thanks Anne Marie Morse. Reminder to our audience that if you would like to ask a question, please type it into the box at the right of your screen. Please remember that we do see questions coming into the box, however, we may not be able to answer all questions due to guidelines or time." },
            { "paragraph": "How can I reduce the amount of sodium in my diet?" },
            { "paragraph": "Here are a few tips: On packaged foods, use the Nutrition Facts label to compare sodium content of foods, choosing the product with less sodium and buying low‐sodium, reduced sodium, or no‐salt‐added versions of products when available. Choose fresh, frozen (no sauce or seasoning), or no‐salt‐added canned vegetables, and fresh poultry, seafood, pork, and lean meat, rather than processed meat and poultry. You could also try eating at home more often; cooking foods from scratch to control the sodium content of dishes, and flavoring foods with herbs and spices instead of salt." },
            { "paragraph": "Thanks Anne Marie Morse. Please remember, if you have asked a question and we have not answered it, it is because we are unable to answer it in this setting." },
            { "paragraph": "Are there any restrictions on activities when taking XYWAV?" },
            { "paragraph": "As with people who take XYREM, anyone who takes XYWAV should not do anything that requires them to be fully awake or is dangerous, including driving a car, using heavy machinery, or flying an airplane, for at least 6 hours after taking XYWAV. Those activities should not be done until you know how XYWAV affects you or your child." },
            { "paragraph": "Do not take XYWAV if you or your child takes other sleep medicines or sedatives (medicines that cause sleepiness), drinks alcohol, or has a rare problem called succinic semialdehyde dehydrogenase deficiency." },
            { "paragraph": "Thank you, Anne Marie Morse. That’s all for now. Please remember, if you have asked a question and we have not answered it, it is because we are unable to answer it in this setting." },
            { "paragraph": "Thanks Anne Marie Morse. As mentioned, please type your questions into the box at the right of your screen." },
            { "paragraph": "How effective is XYWAV?" },
            { "paragraph": "In a clinical study, patients who had their treatment transitioned from XYWAV to placebo experienced a significant worsening in the average number of cataplexy attacks, compared with patients who continued treatment with XYWAV. They also experienced significant worsening of excessive daytime sleepiness, as measured by Epworth Sleepiness Scale (ESS) scores." },
            { "paragraph": " Thank you, Anne Marie Morse." },
            { "paragraph": "Thanks Anne Marie Morse. As mentioned, please type your questions into the box at the right of your screen. Please remember, if you have asked a question and we have not answered it, it is because we are unable to answer it in this setting." },
            { "paragraph": "When are JazzCares Nurse Case Managers and Certified Pharmacy pharmacists available?" },
            { "paragraph": "Your dedicated Nurse Case Manager will be available Monday–Friday, 7 AM–8 PM, Central Time. A pharmacist is also available 24/7 to answer any questions you may have about your prescription" },
            { "paragraph": "Why can’t I get XYWAV at my local pharmacy?" },
            { "paragraph": "Because of the serious risks associated with XYWAV, it is available only through a restricted distribution program called the XYWAV and XYREM REMS. You must be enrolled in this program to receive XYWAV. In addition, the Certified Pharmacy is the only pharmacy in the United States that is permitted to fill your XYWAV prescription and send it directly to you." },
            { "paragraph": "Can I take XYWAV with sedative hypnotic medications?" },
            { "paragraph": "Do not take XYWAV if you take sedatives, which are medicines that cause sleepiness. Let your doctor know if you’re taking these medications." },
            { "paragraph": "Is XYWAV a controlled substance?" },
            { "paragraph": "XYWAV is considered a Schedule III controlled substance. Because of the risks of central nervous system depression and abuse and misuse, XYWAV is available only through a restricted distribution program called the XYWAV and XYREM REMS. You must be enrolled in this program to receive XYWAV. Also, tell your doctor if you or your child have a history of drug abuse." },
            { "paragraph": "Can I drink alcohol and take XYWAV?" },
            { "paragraph": "If you drink alcohol you shouldn’t take XYWAV, since alcohol may increase the depressant effect that XYWAV has on the central nervous system." },
            { "paragraph": "Does XYWAV require refrigeration?" },
            { "paragraph": "XYWAV doesn’t need to be refrigerated. It should be stored in the original bottle before mixing with water, at a temperature between 68°F and 77°F." },
            { "paragraph": "What if I miss my second nightly dose of XYWAV?" },
            { "paragraph": "If you miss a second dose, that dose should be skipped and XYWAV should not be taken again until the next night. Never take 2 doses at the same time." },
            { "paragraph": "How long does it take for XYWAV to start working?" },
            { "paragraph": "You may feel the effects of XYWAV within the first few weeks, but everyone is different. As you begin treatment, be sure to talk with your healthcare provider about how XYWAV is working for you." },
            { "paragraph": "Can I mix XYWAV with fruit juice or a similar sweet drink instead of water to encourage my child to drink it?" },
            { "paragraph": "XYWAV should be mixed only with water." },
            { "paragraph": "What if I prepare a dose of XYWAV, but I don’t drink it that night?" },
            { "paragraph": "If a prepared XYWAV dose is not taken within 24 hours, you should throw the mixture away by emptying it down the sink drain." },
            { "paragraph": "Thank you all for taking the time to join us this afternoon and a special thanks to our ANNE MARIE MORSE speaker, Anne Marie Morse." },
            { "paragraph": "As a reminder to our audience, if you asked a question that wasn’t answered on tonight’s webinar, it is because we are unable to answer the question in this setting. If you have further questions, please connect with your treating physician." },
            { "paragraph": "You will see a separate window open on your screen with a very short survey. We would be very appreciative if you could take a few minutes after this webinar to answer our questions about this program." },
            { "paragraph": "As a reminder the XYWAV Prescribing Information, and medication guide are available for download. To register for future webinars, please visit www.jazzwebinars.com. If you have additional questions please follow up with your doctor." },
            { "paragraph": "This concludes tonight’s Xywav Educational Webinar on Narcolepsy. Enjoy the rest of your night." }
        ],
        "videoResource": [
            {
                "resourceTitle": "Not sure how to talk to your doctor about XYWAV? ",
                "resourceDescription": "We've got a handy guide that can help with that.",
                "resourceLinkName": "DOCTOR DISCUSSION GUIDE",
                "resourceLink": "https://www.xywav.com/narcolepsy/talk-to-your-doctor/"
            },
            {
                "resourceTitle": "Looking for ways to save on the cost of XYWAV?",
                "resourceDescription": "Check out our JazzCares® financial support program.",
                "resourceLinkName": "SAVE ON XYWAV!",
                "resourceLink": "https://www.xywav.com/jazzcares-for-xywav/"
            }
        ]
    },
    {
        "position": 2,
        "videoId": "ceub6tmnxb",
        "videoTitle": "Getting XYWAV",
        "videoDescription": "Learn about the steps you can take to get XYWAV, whether you have never taken XYREM® (sodium oxybate) oral solution, or you are currently taking XYREM. Please see Important Safety Information and full Prescribing Information, including BOXED Warning, and Medication Guide above.",
        "speakerName": "Name",
        "speakerTitle": "Title",
        "thumbnail": "https://embed-ssl.wistia.com/deliveries/ec33cc9f6c8b317e0c094aed3a0525f5.png",
        "videoTranscript": [
            { "paragraph": "Welcome to our Jazz Pharmaceuticals Webinar. Today, we’ll walk through the steps you can take to get XYWAV, whether you have never taken XYREM® (sodium oxybate) oral solution, or you are now taking XYREM." },
            { "paragraph": "Chapter 1: Getting XYWAV \r XYWAV and XYREM REMS Step-By-Step Guide For Getting XYWAV The Certified Pharmacy Your JazzCares® Nurse Manager Chapter 2: Support and Resources JazzCares® for XYWAV Support and Resources for Eligible Patients Insurance Coverage for XYWAV " },
            { "paragraph": "During today’s presentation, we’ll discuss: The XYWAV and XYREM REMS, a distribution program designed to protect you from the risks of improper use  The Certified Pharmacy, which is the only pharmacy in the US permitted to fill a XYWAV prescription  JazzCares® for XYWAV,  which offers support and potential savings  A step-by-step guide for getting XYWAV" },
            { "paragraph": "There are a few topics we won’t be able to address today. We can’t say how much you may pay for XYWAV, or whether or not it’s covered by your insurance We cannot offer you guidance or medical advice as to how XYWAV will affect you personally Only your healthcare provider can determine whether or not you are an appropriate patient for XYWAV And since there were no head-to-head studies between XYWAV and XYREM, we can’t make any direct comparisons between the two medications in terms of efficacy or safety" },
            { "paragraph": "XYWAV® (calcium, magnesium, potassium, and sodium oxybates) oral solution, 0.5 g/mL total salts (equivalent to 0.413 g/mL of oxybate) is a prescription medicine used to treat:" },
            { "paragraph": "the following symptoms in people 7 years of age or older with narcolepsy: sudden onset of weak or paralyzed muscles (cataplexy) excessive daytime sleepiness (EDS)" },
            { "paragraph": "Important Safety Information" },
            { "paragraph": "WARNING: Taking XYWAV with other central nervous system (CNS) depressants such as medicines used to make you or your child fall asleep, including opioid analgesics, benzodiazepines, sedating antidepressants, antipsychotics, sedating anti-epileptic medicines, general anesthetics, muscle relaxants, alcohol, or street drugs, may cause serious medical problems, including trouble breathing (respiratory depression), low blood pressure (hypotension), changes in alertness (drowsiness), fainting (syncope), and death." },
            { "paragraph": "The active ingredient of XYWAV is a form of gamma-hydroxybutyrate (GHB). Abuse or misuse of illegal GHB alone or with other drugs that cause changes in alertness (or consciousness) has caused serious side effects. These effects include seizures, trouble breathing (respiratory depression), changes in alertness (drowsiness), coma, and death. Call your doctor right away if you or your child has any of these serious side effects." },
            { "paragraph": "Because of these risks, you have to go through the XYWAV and XYREM REMS to have your or your child’s prescription for XYWAV filled." },
            { "paragraph": "Please see Important Safety Information and full Prescribing Information, including BOXED Warning, and Medication Guide at XYWAV.com" },
            { "paragraph": "Do not take XYWAV if you take or your child takes other sleep medicines or sedatives (medicines that cause sleepiness), drinks alcohol, or has a rare problem called succinic semialdehyde dehydrogenase deficiency." },
            { "paragraph": "Keep XYWAV in a safe place to prevent abuse and misuse. Selling or giving away XYWAV may harm others, and is against the law. Tell your doctor if you have ever abused or been dependent on alcohol, prescription medicines, or street drugs." },
            { "paragraph": "Anyone who takes XYWAV should not do anything that requires them to be fully awake or is dangerous, including driving a car, using heavy machinery, or flying an airplane, for at least 6 hours after taking XYWAV. Those activities should not be done until you know how XYWAV affects you or your child." },
            { "paragraph": "XYWAV can cause serious side effects, including the following:" },
            { "paragraph": "Breathing problems, including slower breathing, trouble breathing, and/or short periods of not breathing while sleeping (sleep apnea). People who already have breathing or lung problems have a higher chance of having breathing problems when they use XYWAV." },
            { "paragraph": "Mental health problems, including confusion, seeing or hearing things that are not real (hallucinations), unusual or disturbing thoughts (abnormal thinking), feeling anxious or upset, depression, thoughts of killing yourself or trying to kill yourself, increased tiredness, feelings of guilt or worthlessness, or difficulty concentrating. Tell your doctor if you or your child have or had depression or have tried to harm yourself or themselves. Call your doctor right away if you have or your child has symptoms of mental health problems or a change in weight or appetite." },
            { "paragraph": "Sleepwalking. XYWAV can cause sleepwalking, which can cause injuries. Call your doctor if this occurs." },
            { "paragraph": "The most common side effects of XYWAV in adults include nausea, headache, dizziness, anxiety, insomnia, decreased appetite, excessive sweating (hyperhidrosis), vomiting, diarrhea, dry mouth, parasomnia (a sleep disorder that can include abnormal dreams, abnormal rapid eye movement (REM) sleep, sleep paralysis, sleep talking, sleep terror, sleep-related eating disorder, sleepwalking, and other abnormal sleep-related events), somnolence, fatigue, and tremor." },
            { "paragraph": "The most common side effects of XYREM (which also contains oxybate like XYWAV) in children include nausea, bedwetting, vomiting, headache, weight decrease, decreased appetite, dizziness, and sleepwalking." },
            { "paragraph": "XYWAV can cause physical dependence and craving for the medicine when it is not taken as directed. These are not all the possible side effects of XYWAV." },
            { "paragraph": "You are encouraged to report negative side effects of prescription drugs to the FDA. Visit www.fda.gov/medwatch, or call 1-800-FDA-1088." },
            { "paragraph": "First, let’s take a look at how people who are not currently taking XYREM can get XYWAV." },
            { "paragraph": "Because of the serious risks associated with XYWAV, the Food and Drug Administration, or FDA, has required a special program called the XYWAV and XYREM REMS, or Risk Evaluation and Mitigation Strategy. A REMS is a program to manage known or potential serious risks associated with a drug and is required by the FDA to ensure the benefits of the drug outweigh its risks. The XYWAV and XYREM REMS ensures that only the Certified Pharmacy can be used to fill your prescription and send your medication to you. The XYWAV and XYREM REMS is designed to ensure that prescribers and patients are educated on and understand the risks and safe use conditions of XYWAV and XYREM and agree to follow the requirements of the XYWAV and XYREM REMS. Enrollment in the REMS by prescribers and patients is required by the FDA to ensure the benefits of XYWAV outweigh the risks associated with XYWAV." },
            { "paragraph": "Through the XYWAV and XYREM REMS, you’ll have 24/7 phone access to a pharmacist to answer questions about your prescription. The program also enables you to arrange overnight delivery of your XYWAV shipment. Your prescription can be delivered to your door, a local overnight carrier hub, or another location, if approved by the Certified Pharmacy." },
            { "paragraph": "For those who have never taken XYREM, here’s a 5-step process for getting XYWAV. First, your healthcare provider initiates and completes your enrollment process, faxes your prescription to the Certified Pharmacy, and works with the pharmacy through the insurance process." },
            { "paragraph": "Let’s learn about what the Certified Pharmacy is and what it does. This is the only pharmacy in the US permitted to fill a XYWAV prescription and send it directly to you. It’s staffed by dedicated pharmacists and JazzCares® Nurse Case Managers who are available to offer personalized attention and support. They will help you learn how to use XYWAV properly, and help make sure that you get your prescription securely. As mentioned previously, pharmacists are available via phone 24/7 to answer questions about XYWAV treatment." },
            { "paragraph": "In step 2, your JazzCares Nurse Case Manager calls you within 2 to 3 days after you’ve been prescribed XYWAV to welcome you to the program, walk you through the program details, answer your questions, review your insurance information, and make sure you’re aware of available financial support programs for which you may be eligible." },
            { "paragraph": "Now let’s see what your Your JazzCares Nurse Case Manager can offer you. JazzCares for XYWAV is committed to helping you get the personalized support and resources you need." },
            { "paragraph": "Your JazzCares Nurse Case Manager will be with you from the very beginning and will support you in your treatment journey. For example, you can count on your Nurse Case Manager to explain the details of the JazzCares program, help you understand the process of getting XYWAV, and provide ongoing support and resources that are tailored to your needs as you start and stay on XYWAV therapy. " },
            { "paragraph": "You can contact your JazzCares Nurse Case Manager Monday through Friday, 7 AM – 8 PM, Central Time." },
            { "paragraph": "In the next step, a pharmacist from the Certified Pharmacy calls you to review the safety information about XYWAV, guide you through the XYWAV Patient Quick Start Guide and/or the Brochure for Pediatric Patients and Their Caregivers, review your current medications, medical history, and allergies, and ensure you’re fully prepared for the first shipment." },
            { "paragraph": "You have to take this call in order to schedule your first shipment of XYWAV. If you are not available to answer, the pharmacist will leave a message. It is important that you call back or you will not receive your XYWAV prescription." },
            { "paragraph": "In step 4, the Certified Pharmacy ships XYWAV to you overnight. XYWAV can be delivered right to your door, a local overnight carrier hub, or another location, if approved by the Certified Pharmacy. Keep in mind that you or another designated adult must be available to sign for it. Also, the first shipment of XYWAV must not exceed a 30-day supply." },
            { "paragraph": "A copy of the XYWAV Patient Quick Start Guide and/or the Brochure for Pediatric Patients and Their Caregivers is included in your first shipment." },
            { "paragraph": "In step 5, the Certified Pharmacy enables you to schedule your prescription refills online or through an automated phone line, sends you refill reminders based on your preferences (text, email, phone, or all of the above) and arranges for delivery of refills." },
            { "paragraph": "Now let’s look at the process of getting XYWAV for those already taking XYREM." },
            { "paragraph": "If you are currently taking XYREM, your healthcare provider can transition you to XYWAV. You are already enrolled in the XYWAV and XYREM REMS and do not need to be re-enrolled. Your healthcare provider is recommended to initially prescribe XYWAV at the same dose and regimen as your XYREM treatment. If needed, your healthcare provider may adjust your dosage or dosing routine." },
            { "paragraph": "Stay tuned to learn more in the next video chapter: Support and Resources" },
            { "paragraph": "Let’s take a look at patient support programs and resources available to those who are prescribed XYWAV." },
            { "paragraph": "JazzCares® for XYWAV offers financial assistance programs such as the XYWAV Coupon Program. If you are eligible, you may pay as little as $5 per prescription. Through the XYWAV Quick Start Voucher, you may be eligible to receive a free 1-month supply of XYWAV if you are waiting for insurance coverage. If you’re experiencing delays in getting insurance approval, you may be eligible for up to 4 months of free XYWAV through the XYWAV Bridge Program while waiting for coverage approval. And if you are uninsured or your insurance does not cover XYWAV, you may receive treatment at no cost through the XYWAV Patient Assistance Program. If this applies to you, you may submit your application online. And your JazzCares Nurse Case Manager will be with you from the very beginning and will support you in your treatment journey." },
            { "paragraph": "To enroll in JazzCares for XYWAV, just contact your Nurse Case Manager at 1-866-997-3688, and then sign the Patient Authorization Form at JazzCaresConsent.com." },
            { "paragraph": "Other support programs include myWAV, an online resource for patients who have been prescribed XYWAV. When you sign up at myWAV.com, you’ll automatically get access to information tailored to you, the myWAV app, helpful treatment information and tips, and much more. The XYWAV Mentor Program connects you 1:1 with another person taking XYWAV. Our Patient Mentors understand what you are going through, can share their experience, and listen to you. You can access other Jazz Pharmaceuticals Webinars online at XYWAV.com, and watch healthcare providers who are experts in narcolepsy and XYWAV treatment give presentations, like this one, about a variety of aspects related to narcolepsy, as well as how XYWAV may treat your cataplexy and/or EDS." },
            { "paragraph": "When you are prescribed XYWAV, ask your healthcare provider for a XYWAV Starter Kit. You’ll find plenty of helpful tips about preparing for and beginning your treatment.Your first shipment of XYWAV will come with a Welcome Kit, including a welcome video, an alarm clock, and information to help you begin treatment.When you sign up to receive text or email prescription refill reminders, your reminder will include a link to a secure online prescription refill portal to schedule your next XYWAV shipment." },
            { "paragraph": "Next we’ll look at insurance coverage for XYWAV. The fact is, 81% of insured patients are covered nationwide. Your JazzCares Nurse Case Manager will review your insurance information with you, and will help determine if you are eligible for participation in any financial assistance programs. The Certified Pharmacy will assist you with navigating the insurance process, and understanding your insurance coverage to help get your treatment started." },
            { "paragraph": "Here’s what you can do once your healthcare provider submits your prescription: Register for myWAV at myWAV.com Prepare for calls from the Certified Pharmacy Enroll in JazzCares® for XYWAV" },
            { "paragraph": "So to wrap it all up, what are our key takeaways from this presentation?" },
            { "paragraph": "Let's summarize what we've learned... Because of the risk of central nervous system depression, abuse, and misuse, XYWAV is available only by prescription from the Certified Pharmacy, through the XYWAV and XYREM REMS If you are not taking XYREM, your healthcare provider can start you on a 5-step process involving your JazzCares Nurse Case Manager and the Certified Pharmacy to get XYWAV  If you are currently taking XYREM, your healthcare provider can transition you to XYWAY" },
            { "paragraph": "Let’s look at some potential next steps. You can: Ask your healthcare provider if XYWAV is right for you, whether or not you’re taking XYREM Go to XYWAV.com to learn about savings programs and educational resources Go to JazzCares.com to learn about personalized support services" },
            { "paragraph": "Thank you for your time today! I hope this presentation has given you useful information about how to get XYWAV." },
            { "paragraph": "" }
        ],
        "videoResource": [
            {
                "resourceTitle": "Not sure how to talk to your doctor about XYWAV?",
                "resourceDescription": "We've got a handy guide that can help with that.",
                "resourceLinkName": "Doctor discussion Guide",
                "resourceLink": "https://www.xywav.com/narcolepsy/talk-to-your-doctor/"
            },
            {
                "resourceTitle": "Looking for ways to save on the cost of XYWAV?",
                "resourceDescription": "Check out our JazzCares® financial support program.",
                "resourceLinkName": "save on xywav!",
                "resourceLink": "https://www.xywav.com/jazzcares-for-xywav/ "
            },
            {
                "resourceTitle": "With JazzCares, find support for every step of your treatment journey.",
                "resourceDescription": "JazzCares provides services that fit your individual needs every step of the way.",
                "resourceLinkName": "LEARN ABOUT JAZZCARES",
                "resourceLink": "https://www.xywav.com/patient-support-programs/"
            }
        ]
    },
    {
        "position": 3,
        "videoId": "qglqwbbe42",
        "videoTitle": "First Few Weeks on XYWAV",
        "videoDescription": "Learn what you may expect during the first few weeks of XYWAV treatment for narcolepsy. Please see Important Safety Information and full Prescribing Information, including BOXED Warning, and Medication Guide above.",
        "speakerName": "Name",
        "speakerTitle": "Title",
        "thumbnail": "https://embed-ssl.wistia.com/deliveries/a10405c66d25a5f60978a94d0f36eade.png",
        "videoTranscript": [
            { "paragraph": "Welcome to our Jazz Pharmaceuticals Webinar. Today, we’ll discuss what you may expect during the first few weeks of XYWAV treatment." },
            { "paragraph": "During today’s presentation, we’ll discuss: Receiving your first prescription shipment, and what’s included with your medication. XYWAV dosing per your doctor’s instructions. The titration process, which refers to adjusting your dosage over time." },
            { "paragraph": "There are a few topics we won’t be able to address today.  What your XYWAV dosage may be. Only your doctor can determine the dosage that’s right for you. XYWAV side effects that you personally may experience. How you should fit XYWAV treatment into your routine. How effective XYWAV will be for you personally" },
            { "paragraph": "XYWAV® (calcium, magnesium, potassium, and sodium oxybates) oral solution, 0.5 g/mL total salts (equivalent to 0.413 g/mL of oxybate) is a prescription medicine used to treat:" },
            { "paragraph": "the following symptoms in people 7 years of age or older with narcolepsy:" },
            { "paragraph": "sudden onset of weak or paralyzed muscles (cataplexy)" },
            { "paragraph": "excessive daytime sleepiness (EDS)" },
            { "paragraph": "Important Safety Information" },
            { "paragraph": "WARNING: Taking XYWAV with other central nervous system (CNS) depressants such as medicines used to make you or your child fall asleep, including opioid analgesics, benzodiazepines, sedating antidepressants, antipsychotics, sedating anti-epileptic medicines, general anesthetics, muscle relaxants, alcohol, or street drugs, may cause serious medical problems, including trouble breathing (respiratory depression), low blood pressure (hypotension), changes in alertness (drowsiness), fainting (syncope), and death." },
            { "paragraph": "The active ingredient of XYWAV is a form of gamma-hydroxybutyrate (GHB). Abuse or misuse of illegal GHB alone or with other drugs that cause changes in alertness (or consciousness) has caused serious side effects. These effects include seizures, trouble breathing (respiratory depression), changes in alertness (drowsiness), coma, and death. Call your doctor right away if you or your child has any of these serious side effects." },
            { "paragraph": "Because of these risks, you have to go through the XYWAV and XYREM REMS to have your or your child’s prescription for XYWAV filled." },
            { "paragraph": "Please see Important Safety Information and full Prescribing Information, including BOXED Warning, and Medication Guide at XYWAV.com" },
            { "paragraph": "Do not take XYWAV if you take or your child takes other sleep medicines or sedatives (medicines that cause sleepiness), drinks alcohol, or has a rare problem called succinic semialdehyde dehydrogenase deficiency." },
            { "paragraph": "Keep XYWAV in a safe place to prevent abuse and misuse. Selling or giving away XYWAV may harm others, and is against the law. Tell your doctor if you have ever abused or been dependent on alcohol, prescription medicines, or street drugs." },
            { "paragraph": "Anyone who takes XYWAV should not do anything that requires them to be fully awake or is dangerous, including driving a car, using heavy machinery, or flying an airplane, for at least 6 hours after taking XYWAV. Those activities should not be done until you know how XYWAV affects you or your child. XYWAV can cause serious side effects, including the following:" },
            { "paragraph": "Breathing problems, including slower breathing, trouble breathing, and/or short periods of not breathing while sleeping (sleep apnea). People who already have breathing or lung problems have a higher chance of having breathing problems when they use XYWAV." },
            { "paragraph": "Mental health problems, including confusion, seeing or hearing things that are not real (hallucinations), unusual or disturbing thoughts (abnormal thinking), feeling anxious or upset, depression, thoughts of killing yourself or trying to kill yourself, increased tiredness, feelings of guilt or worthlessness, or difficulty concentrating. Tell your doctor if you or your child have or had depression or have tried to harm yourself or themselves. Call your doctor right away if you have or your child has symptoms of mental health problems or a change in weight or appetite." },
            { "paragraph": "Sleepwalking. XYWAV can cause sleepwalking, which can cause injuries. Call your doctor if this occurs." },
            { "paragraph": "The most common side effects of XYWAV in adults include nausea, headache, dizziness, anxiety, insomnia, decreased appetite, excessive sweating (hyperhidrosis), vomiting, diarrhea, dry mouth, parasomnia (a sleep disorder that can include abnormal dreams, abnormal rapid eye movement (REM) sleep, sleep paralysis, sleep talking, sleep terror, sleep-related eating disorder, sleepwalking, and other abnormal sleep-related events), somnolence, fatigue, and tremor." },
            { "paragraph": "The most common side effects of XYREM (which also contains oxybate like XYWAV) in children include nausea, bedwetting, vomiting, headache, weight decrease, decreased appetite, dizziness, and sleepwalking." },
            { "paragraph": "XYWAV can cause physical dependence and craving for the medicine when it is not taken as directed. These are not all the possible side effects of XYWAV." },
            { "paragraph": "You are encouraged to report negative side effects of prescription drugs to the FDA. Visit www.fda.gov/medwatch, or call 1-800-FDA-1088." },
            { "paragraph": "First, let’s take a look at what you can expect when you receive your first shipment of XYWAV." },
            { "paragraph": "Your first shipment of XYWAV will be delivered by the Certified Pharmacy to the address you specified with the pharmacist. This address could be:" },
            { "paragraph": "Your door - A local overnight carrier hub - Another location, if approved by the Certified Pharmacy" },
            { "paragraph": "Keep in mind that you or another designated adult must be available to sign for it. This is very important because XYWAV is a controlled substance and only available through the Certified Pharmacy with the XYWAV and XYREM REMS. These rules apply to every shipment of XYWAV." },
            { "paragraph": "Along with your first shipment of XYWAV, you will receive a Welcome Kit including:  A QR code on the Welcome Kit box linking to the welcome video, which explains important next steps to take when you receive your prescription; The XYWAV Patient Quick Start Guide or Brochure for Pediatric Patients and Their Caregivers. These brochures provide information on the serious risks and safe use of XYWAV and answer important questions about how to use XYWAV properly and how to store it safely" },
            { "paragraph": "The Welcome Kit will also include: The XYWAV Medication Guide, which includes important safety information, how to store XYWAV, how to prepare doses and take XYWAV, and how to dispose of XYWAV - The XYWAV Support Brochure, explaining the support and service options available to you" },
            { "paragraph": "And finally, the Welcome Kit includes: An alarm clock so you don’t miss taking or administering the second nightly dose - Other important information from the Certified Pharmacy" },
            { "paragraph": "Next, we’ll discuss XYWAV dosing" },
            { "paragraph": "Let’s talk about the titration process. This refers to the gradual change in dosage that your doctor may recommend for you in order to find the right dose to fit your needs." },
            { "paragraph": "The XYWAV dosage that works for someone else may not be the best dosage for you or your child. That’s why during the first few weeks of treatment, your healthcare provider may change your dosage. It is very important that you follow the healthcare provider’s dosing instructions exactly" },
            { "paragraph": "This process is called titration. It helps your healthcare provider find the appropriate XYWAV dosage that works for you" },
            { "paragraph": "You may see an improvement in symptoms during the titration period. You may also experience adverse reactions during this time" },
            { "paragraph": "It’s normal to feel a little nervous your first night of taking XYWAV. So let’s go through the details of preparing and taking your XYWAV doses. XYWAV is taken at night, divided into 2  doses." },
            { "paragraph": "For adults:" },
            { "paragraph": "Prepare your 2 doses at the same time, prior to bedtime, and put them in a safe and secure place. Before you take or give XYWAV, each dose should be mixed with approximately ¼ cup of water in the empty pharmacy containers included in each shipment" },
            { "paragraph": "Take your first dose while in bed and lie down immediately" },
            { "paragraph": "Take the second dose 2½ to 4 hours later, as instructed by your doctor, while in bed and lie down immediately after dosing" },
            { "paragraph": "You should remain in bed after taking each dose of XYWAV" },
            { "paragraph": "For children:" },
            { "paragraph": "Give the first XYWAV dose at bedtime or after an initial period of sleep, while your child is in bed, and have them lie down immediately" },
            { "paragraph": "Give the second XYWAV dose 2½ to 4 hours after the first XYWAV dose. You may want to set an alarm clock to help you wake up to give the second XYWAV dose" },
            { "paragraph": "Your child should remain in bed after taking the first and second doses of XYWAV" },
            { "paragraph": "If you or your child miss the second nightly dose, skip that dose and do not take XYWAV again until the next night." },
            { "paragraph": "Never take 2 doses at the same time" },
            { "paragraph": "If you do not use any dose within 24 hours of mixing it with water, throw it away" },
            { "paragraph": "XYWAV can cause you or your child to suddenly fall asleep without first feeling drowsy. This is why you or your child need to be in bed for each dose." },
            { "paragraph": "You or your child will likely fall asleep within 5 to 15 minutes; the time may vary from night to night" },
            { "paragraph": "Falling asleep quickly, including while standing or while getting up from the bed, has led to falls with injuries that have required some people to be hospitalized" },
            { "paragraph": "Setting an alarm should help you or your child wake up" },
            { "paragraph": "Next, we’ll discuss the efficacy and safety of XYWAV." },
            { "paragraph": "The efficacy and safety of XYWAV for treatment of cataplexy and/or EDS in adult patients with narcolepsy was demonstrated in a clinical trial, involving 201 patients with narcolepsy with cataplexy from 18 to 70 years of age." },
            { "paragraph": "Let’s take a look at how the trial was carried out. First, for up to 30 days, patients were evaluated for eligibility to participate in the study. These patients were taking XYREM, XYREM and another cataplexy medication, a non-XYREM cataplexy medication, or no medication for cataplexy." },
            { "paragraph": "Next, patients took XYWAV for 12 weeks. Patients who had been taking XYREM were switched to XYWAV at a gram-for-gram dose. Patients also had their dose of XYWAV adjusted based on efficacy and tolerability until the right dose was found for each patient. Patients who had been taking non-XYREM medications for cataplexy were gradually tapered off those medications." },
            { "paragraph": "After this 12-week period, all patients continued to take their stable dose of XYWAV for 2 weeks." },
            { "paragraph": "Then, for 2 weeks, some patients continued taking XYWAV. Others stopped taking XYWAV and took a placebo. During this 2-week period, patients did not know whether they were taking XYWAV or a placebo." },
            { "paragraph": "And here are the results from the clinical trial. Compared to patients who continued taking XYWAV, patients who were switched to placebo had an increase in weekly number of cataplexy attacks and worsening of EDS. This showed that XYWAV treats both cataplexy and EDS." },
            { "paragraph": "You may experience side effects with XYWAV treatment. For adults, the most common side effects include:" },
            { "paragraph": "Headache" },
            { "paragraph": "Nausea" },
            { "paragraph": "Dizziness" },
            { "paragraph": "Decreased appetite" },
            { "paragraph": "Parasomnia" },
            { "paragraph": "Diarrhea" },
            { "paragraph": "Excessive sweating" },
            { "paragraph": "Anxiety" },
            { "paragraph": "Vomiting" },
            { "paragraph": "The XYWAV clinical trial did not include children. The safety profile in pediatric patients with XYWAV is based on findings from a study of pediatric patients taking XYREM." },
            { "paragraph": "For children, the most common side effects of XYREM, which also contains oxybate like XYWAV, include:" },
            { "paragraph": "Nausea" },
            { "paragraph": "Bedwetting" },
            { "paragraph": "Vomiting" },
            { "paragraph": "Headache" },
            { "paragraph": "Weight decrease" },
            { "paragraph": "Decreased appetite" },
            { "paragraph": "Dizziness" },
            { "paragraph": "Sleepwalking" },
            { "paragraph": "However, in patients who remained on treatment during the clinical study, side effects tended to occur early and diminish over time" },
            { "paragraph": "Overall, adverse reactions related to XYWAV were reported less frequently in patients who were taking XYREM when they entered the study than in patients who weren’t taking XYREM" },
            { "paragraph": "There are other possible side effects of XYWAV." },
            { "paragraph": "For more information, ask your healthcare provider, pharmacist, or JazzCares Nurse Case Manager" },
            { "paragraph": "Call your healthcare provider for medical advice about side effects" },
            { "paragraph": "You may report side effects to the FDA at 1-800-FDA-1088" },
            { "paragraph": "Now let’s take a look at the patient support programs and resources available with XYWAV." },
            { "paragraph": "Be sure to enroll in JazzCares for XYWAV, the patient support program. JazzCares is designed to give you the support and assistance you need, right from the start, and throughout your treatment journey. If you haven’t enrolled in JazzCares by the time you receive your prescription, unlock the resources available to you by signing the Patient Authorization Form on JazzCares.com/XYWAV today!" },
            { "paragraph": "Let’s learn more about the JazzCares support offerings." },
            { "paragraph": "They include financial assistance programs such as the XYWAV Coupon Program. If you are eligible, you can pay as little as $5." },
            { "paragraph": "Are you waiting for your insurance to cover XYWAV? You may be eligible to receive a free 1-month supply of XYWAV with the Quick Start Voucher." },
            { "paragraph": "Life can change quickly. Your treatment with XYWAV may stay constant. The Bridge Program can provide eligible patients up to 4 months of XYWAV for free." },
            { "paragraph": "Also, JazzCares may have you covered, even if you do not have coverage. If you are uninsured or your insurance does not cover XYWAV, you may be eligible to receive treatment for free." },
            { "paragraph": "And remember, you’re not alone. Receive trusted support from your dedicated Nurse Case Manager, right from the start. Your dedicated Nurse Case Manager assesses your unique needs, and can help you navigate social support, address practical challenges, and help motivate you!" },
            { "paragraph": "Now let’s see what your dedicated Nurse Case Manager can offer you." },
            { "paragraph": "Your Nurse Case Manager will be with you right from the start and will support you in your treatment journey. For example, you can count on your Nurse Case Manager to explain the details of the JazzCares program, help you understand the process of getting XYWAV, and provide ongoing support and resources that are tailored to your needs as you start and stay on XYWAV therapy." },
            { "paragraph": "You can contact your JazzCares Nurse Case Manager Monday through Friday, 7 AM – 8 PM, Central Time." },
            { "paragraph": "Other JazzCares for XYWAV resources include:" },
            { "paragraph": "Refilling your XYWAV is easy and convenient with our online refill scheduling and reminder. You can sign up to receive prescription refill reminders via text or email, and every reminder will include a link to a secure online prescription refill portal to schedule your next XYWAV shipment" },
            { "paragraph": "Tools and resources to help you consider your overall health when managing your narcolepsy" },
            { "paragraph": "And when you or a loved one has questions about your Jazz medicine, JazzCares has the resources you need" },
            { "paragraph": "Before you receive your first shipment, be sure to create your myWAV account. myWAV is your 24/7 personalized tool for up-to-date support and motivation that is tailored to your needs.After creating your myWAV account, be sure to download the myWAV app and personalize your myWAV experience. All you’ll need to do is answer a few questions to tailor the support you receive from myWAV.You can use myWAV to:" },
            { "paragraph": "Make sure you complete all necessary steps to get your first prescription shipment, your first refill, and continue treatment" },
            { "paragraph": "Understand how to prepare and take XYWAV—you’ll find a supplies checklist to help you make sure you have everything ready to start preparing your nightly XYWAV doses, step-by-step dose preparation instructions, and directions on how to take XYWAV" },
            { "paragraph": "You can also use myWAV to:" },
            { "paragraph": "Learn what to expect when you’re taking XYWAV, including how soon you may start to see a reduction in your symptoms and possible side effects and what to do if you experience them" },
            { "paragraph": "Learn more about JazzCares for XYWAV, including nurse and pharmacy support, access and affordability, personalized resources, and personalized support" },
            { "paragraph": "Get help understanding insurance coverage benefits, if you switch insurance plans" },
            { "paragraph": "Access educational materials to help family and friends understand the symptoms and impact of narcolepsy, and how to be supportive" },
            { "paragraph": "Set treatment goals" },
            { "paragraph": "You can also use myWAV to:" },
            { "paragraph": "Create a plan to achieve your health and treatment goals" },
            { "paragraph": "Learn about safely storing, traveling with, and disposing of XYWAV" },
            { "paragraph": "Get tips about setting and keeping a routine that works with your XYWAV dosing regimen And much more!" },
            { "paragraph": "You can also track your progress using the myWAV app. Assessing your symptoms over time can help you understand how XYWAV is helping you." },
            { "paragraph": "You can use the ESS Screener to assess the severity of your excessive daytime sleepiness, as measured by Epworth Sleepiness Scale scores. Another survey helps you monitor the frequency of your cataplexy over time." },
            { "paragraph": "Let’s learn about the XYWAV Mentor Program." },
            { "paragraph": "We’re better together. That’s why Jazz Pharmaceuticals offers a 1-on-1 peer mentor program to connect you with another patient to make sure you’re not alone" },
            { "paragraph": "The XYWAV Patient Mentors understand what you’re going through" },
            { "paragraph": "They’re here to support, listen, and share their own experiences with you" },
            { "paragraph": "Register to be connected with a mentor by calling 1-866-539-3946." },
            { "paragraph": "Let’s meet our XYWAV Patient Mentors." },
            { "paragraph": "Anna started showing symptoms of narcolepsy, including excessive daytime sleepiness, sleep-related hallucinations, and cataplexy attacks, at the age of 16. She was not officially diagnosed with narcolepsy type 1—narcolepsy with cataplexy—until she was 20. At first, she tried multiple stimulants, but she quickly stopped taking them and transitioned to XYREM." },
            { "paragraph": "She transitioned to XYWAV treatment in November 2020. Brooke attended over 30 doctors’ appointments before she was finally diagnosed with narcolepsy. Brooke was sleeping a lot and was showing signs of cataplexy, and she had a friend who had been diagnosed with narcolepsy, so Brooke asked to be tested for narcolepsy too." },
            { "paragraph": "After seeing a sleep specialist and receiving a diagnosis of narcolepsy type 1, she was prescribed wake-promoting agents and an SNRI, and moved on to treatment with XYREM. She transitioned to XYWAV treatment in December of 2020." },
            { "paragraph": "Her parents struggled to cope with her symptoms at first, but now that she has her diagnosis she has rebuilt her relationship with them." },
            { "paragraph": "Michelle was always very sleepy as a child and later started having cataplexy attacks. She has many stories of her appearing unsteady, falling down, and a lot of embarrassment due to what she now knows were cataplexy episodes. In 2003, her doctor sent her to a neurologist who diagnosed her with both narcolepsy and epilepsy." },
            { "paragraph": "At first, she was prescribed XYREM and transitioned to XYWAV in December 2020. Jami had her first episode of cataplexy at the age of 17." },
            { "paragraph": "Her excessive daytime sleepiness steadily grew worse over the next several years, and her doctor, thinking she was bipolar and perhaps had a mild case of narcolepsy, prescribed psychiatric meds and a wake-promoting agent. A sleep specialist diagnosed her with narcolepsy, and she started taking XYREM in 2008, which helped treat her cataplexy and EDS." },
            { "paragraph": "She transitioned to XYWAV in December 2020." },
            { "paragraph": "Making sure you have XYWAV, when you need it. Of course, you’ll always want to have your XYWAV on hand. That’s why XYWAV offers an easy-to-follow refill process." },
            { "paragraph": "You can sign up to receive refill reminders through text or email. Each reminder will include a link to a secure online prescription refill portal. Call the Certified Pharmacy at 866-997-3688 to sign up!" },
            { "paragraph": "Your JazzCares Nurse Case Manager will continue calling on a regular basis; be sure to ask about our support offerings." },
            { "paragraph": "So to wrap it all up, what are our key takeaways from this presentation?" },
            { "paragraph": "Let's summarize what we've learned..." },
            { "paragraph": "- Efficacy of XYWAV" },
            { "paragraph": "- Common side effects" },
            { "paragraph": "- The support offered by JazzCares for XYWAV, myWAV, and the XYWAV Mentor Program" },
            { "paragraph": "- Steps to get your first refill" },
            { "paragraph": "Let’s look at some potential next steps. If you’ve been prescribed XYWAV…" },
            { "paragraph": "- Register with the XYWAV support programs. Just go to JazzCaresConsent.com and sign the Patient Authorization Form" },
            { "paragraph": "- Download the myWAV app from the Apple App Store or Google Play Store" },
            { "paragraph": "If you haven’t been prescribed XYWAV…" },
            { "paragraph": "- Ask your healthcare provider if XYWAV is right for you" },
            { "paragraph": "And remember, all the information we’ve just reviewed, as well as so much of what you’ll need to know for your first few weeks taking XYWAV, is available on myWAV.com." },
            { "paragraph": "Thank you for your time today! I hope this presentation has given you useful information about what you may expect during your first few weeks of XYWAV treatment." }
        ],
        "videoResource": [
            {
                "resourceTitle": "With JazzCares®, find support for every step of your treatment journey.",
                "resourceDescription": "JazzCares provides services that fit your individual needs every step of the way.",
                "resourceLinkName": "LEARN ABOUT JAZZCARES",
                "resourceLink": "https://www.xywav.com/patient-support-programs/"
            }
        ]
    },
    {
        "position": 4,
        "videoId": "k9mrr6wab8",
        "videoTitle": "The History and Use of Oxybates in Narcolepsy",
        "videoDescription": "Discover the history of oxybates used to treat cataplexy and excessive daytime sleepiness in narcolepsy, including XYWAV. Please see Important Safety Information and full Prescribing Information, including BOXED Warning, and Medication Guide above.",
        "speakerName": "Logan Schneider, MD",
        "speakerTitle": "Clinical Assistant Professor of Sleep Medicine at Stanford University Medical Center",
        "thumbnail": "https://embed-ssl.wistia.com/deliveries/6bb6285d38a56e0373d4f3d794a20c4e.webp",
        "videoTranscript": [
        ],
        "videoResource": [
            {
                "resourceTitle": "Looking for ways to save on the cost of XYWAV?",
                "resourceDescription": "Check out our JazzCares® financial support program. ",
                "resourceLinkName": "Save On XYWAV!",
                "resourceLink": "https://www.xywav.com/jazzcares-for-xywav/"
            },
            {
                "resourceTitle": "Not sure how to talk to your doctor about XYWAV? ",
                "resourceDescription": "We’ve got a handy guide that can help with that.",
                "resourceLinkName": "Doctor Discussion Guide",
                "resourceLink": "https://www.xywav.com/narcolepsy/talk-to-your-doctor/"
            }

        ]
    },
    {
        "position": 5,
        "videoId": "7ps5ehy8cd",
        "videoTitle": "Understanding Differences in Narcolepsy Symptoms",
        "videoDescription": "Find out about the different ways in which people experience cataplexy and excessive daytime sleepiness, 2 of the hallmark symptoms of narcolepsy and learn how XYWAV may be able to help with those symptoms. Please see Important Safety Information and full Prescribing Information, including BOXED Warning, and Medication Guide above.",
        "speakerName": "",
        "speakerTitle": "",
        "thumbnail": "https://embed-ssl.wistia.com/deliveries/06f0833228d31915627ca95fcd7772fd.jpg",
        "videoTranscript": [
        ],
        "videoResource": [
            {
                "resourceTitle": "Is XYWAV right for you?",
                "resourceDescription": "Have an informed discussion at your next appointment.",
                "resourceLinkName": "Learn how to start the conversation",
                "resourceLink": "https://www.xywav.com/narcolepsy/talk-to-your-doctor/"
            },
            {
                "resourceTitle": "Looking for support and cost savings?",
                "resourceDescription": "JazzCares® for XYWAV may be able to help.",
                "resourceLinkName": "Get the details",
                "resourceLink": "https://www.xywav.com/jazzcares-for-xywav/"
            },
            {
                "resourceTitle": "Living with cataplexy and/or excessive daytime sleepiness in narcolepsy?",
                "resourceDescription": "Considering your cardiovascular health is important too.",
                "resourceLinkName": "Find out why",
                "resourceLink": "https://www.xywav.com/narcolepsy/living-with-narcolepsy/"
            }

        ]
    },
    {
        "position": 6,
        "videoId": "usivi8atz8",
        "videoTitle": 'A Patient’s Perspective on JazzCares<sup class="sub-reg">&reg;</sup>',
        "videoDescription": "Meet Brooke, a real XYWAV patient, and Cyndy, a JazzCares nurse, as they discuss the support offerings available to patients on XYWAV through JazzCares. Please see Important Safety Information and full Prescribing Information, including BOXED Warning, and Medication Guide above.",
        "speakerName": "",
        "speakerTitle": "",
        "thumbnail": "https://embed-ssl.wistia.com/deliveries/23266f1bf26af15dfde2442ac5a1736c.jpg",
        "videoTranscript": [
        ],
        "videoResource": [
            {
                "resourceTitle": "Looking for ways to save on the cost of XYWAV?",
                "resourceDescription": "JazzCares for XYWAV may be able to help.",
                "resourceLinkName": "Get the details",
                "resourceLink": "https://www.xywav.com/jazzcares-for-xywav/"
            },
            {
                "resourceTitle": "Have you looked into your support options with JazzCares? ",
                "resourceDescription": "Check out JazzCares patient support offerings, including myWAV™, a 24/7 online tool. ",
                "resourceLinkName": "Start exploring",
                "resourceLink": "https://www.xywav.com/narcolepsy/patient-support-programs/"
            },
            {
                "resourceTitle": "Living with cataplexy and/or excessive daytime sleepiness in narcolepsy?",
                "resourceDescription": "Hear real patients talk about their narcolepsy diagnosis and treatment journeys.",
                "resourceLinkName": "Watch patient stories",
                "resourceLink": "https://www.xywav.com/patient-stories/"
            }

        ]
    }
]